var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('VIEW_OUTLETS'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
    content: _vm.$t('label.outlets'),
    classes: 'tooltipMenu tooltipMenu--right',
    delay: {
      show: 200,
    },
  }),expression:"{\n    content: $t('label.outlets'),\n    classes: 'tooltipMenu tooltipMenu--right',\n    delay: {\n      show: 200,\n    },\n  }",modifiers:{"right":true}}],attrs:{"data-intercom-tour":"outlets"}},[_c('router-link',{staticClass:"sidebarMenu__link",attrs:{"to":{
      name: 'outlets.index',
      params: {
        businessId: _vm.activeBusiness.id,
      },
    }}},[_c('Icon',{staticClass:"sidebarMenu__icon",attrs:{"icon":"shop"}}),_c('span',{staticClass:"sidebarMenu__name"},[_vm._v(_vm._s(_vm.$t("label.outlets")))])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }