<template>
  <li
    v-if="$can('VIEW_OUTLETS')"
    v-tooltip.right="{
      content: $t('label.outlets'),
      classes: 'tooltipMenu tooltipMenu--right',
      delay: {
        show: 200,
      },
    }"
    data-intercom-tour="outlets"
  >
    <router-link
      class="sidebarMenu__link"
      :to="{
        name: 'outlets.index',
        params: {
          businessId: activeBusiness.id,
        },
      }"
    >
      <Icon icon="shop" class="sidebarMenu__icon" />
      <span class="sidebarMenu__name">{{ $t("label.outlets") }}</span>
    </router-link>
  </li>
</template>

<script>
import { Icon } from "@ds";
import { getActiveBusiness } from "@/utils/mixins";

export default {
  name: "Menu",
  components: {
    Icon,
  },
  mixins: [getActiveBusiness],
};
</script>
